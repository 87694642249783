<template>
  <div>
    <!--<template v-if="command && command.id && basket && basket.id">
      <PackingType1 v-if="[1].includes(command.process_type)" :orders="orders"/>
      <PackingType2 v-else-if="[2].includes(command.process_type)" :orders="orders" :basket="basket" :command="command" />
      <PackingType3 v-else-if="[3,4].includes(command.process_type)" :orders="orders" />
    </template>-->
    <PackingType2
      v-if="command && command.id && basket && basket.id"
      :key="packingKey"
      :orders="orders"
      :basket="basket"
      :command="command"
      :is-missing="is_missing"
      :present-items="presentItems"
      @success="success"
      @finishBasket="finishBasket"
    />
    <v-text-field
      v-else
      v-model.number="code"
      :label="$t('labels.scan_basket_code')"
      autofocus
      dense
      outlined
      clearable
      hide-details
      @keyup.enter="scanBasket"
    ></v-text-field>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.packing_table_code')"
        :description="$t('labels.scan_packing_table_description')"
        :label="$t('labels.packing_table_code')"
        :placeholder="$t('labels.packing_table_code')"
        :codes="checkingCodes"
        :show-cancel-button="false"
        @confirm="setPackingTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";
import { WAREHOUSE_TABLE_PACKING } from "@/libs/const";

export default {
  name: "Packing",
  components: {
    // PackingType1: () => import('@/components/goods_issue/PackingType1'),
    PackingType2: () => import("@/components/goods_issue/PackingType2"),
    // PackingType3: () => import('@/components/goods_issue/PackingType3'),
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
  },
  data: () => ({
    code: null,
    basket: {},
    command: {},
    orders: [],
    tables: [],
    checkingCodes: [],
    presentItems: [],
    dialogConfirm: false,
    is_missing: false,
    isNotifyMissing: false,
  }),
  computed: {
    packingKey() {
      if (!this.orders || this.orders.length === 0) {
        return "packingKey";
      }
      const ordArr = [...this.orders].map((o) => o.id);
      return ordArr.join("");
    },
  },
  mounted() {
    this.getPackingTable();
    /* if (window.me && window.me.working && window.me.working.packing && window.me.working.packing_item) {
      const working = JSON.parse(window.me.working.packing_item)
      this.orders = [...working.orders]
      this.basket = {...working.basket}
      this.command = {...working.command}
      const order = {...working.order}
      this.$vToastify.warning(this.$t('messages.pack_error_stamp_pending', {tracking: order.tracking_id, basket_code: this.basket.code}))
      document.getElementById('error_sound_player').play()
      return false
    } */
  },
  methods: {
    getPackingTable() {
      const table = localStorage.getItem("_pktb");
      if (!table) {
        this.showConfirmDialog();
        return false;
      }
      const { date, code, id } = JSON.parse(table);
      const today = moment().format("YYYYMMDD");
      if (!date || !code || !id || date !== today) {
        this.showConfirmDialog();
        return false;
      }

      const basket_code = localStorage.getItem("_pbct1");
      if (!basket_code) {
        return false;
      }
      this.$vToastify.warning(
        this.$t("messages.processing_one_basket", { basket_code: basket_code })
      );
      /* setTimeout(() => {
        this.code = basket_code
        this.scanBasket()
      }, 500) */
    },
    showConfirmDialog() {
      httpClient
        .post("/me/v1/get-warehouse-tables", { type: WAREHOUSE_TABLE_PACKING })
        .then(({ data }) => {
          const checkingCodes = [...data].map((d) => d.code);
          this.checkingCodes = [...checkingCodes];
          this.tables = [...data];
          this.dialogConfirm = true;
        });
    },
    hideConfirmDialog() {
      this.dialogConfirm = false;
    },
    async setPackingTable(code) {
      const { employee } = window.me;
      if (!code) {
        this.$vToastify.error(this.$t("messages.packing_table_code_required"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      const table = this.tables.find((t) => t.code === code);
      if (!table) {
        this.$vToastify.error(this.$t("messages.packing_table_code_wrong"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (table.id_employee && table.id_employee !== employee.id) {
        this.$vToastify.error(
          this.$t("messages.packing_table_code_using_other_employee", {
            name: table.employee_name,
          })
        );
        document.getElementById("error_sound_player").play();
        return false;
      }

      try {
        await httpClient.post("/me/v1/set-using-warehouse-table", {
          id: table.id,
          type: WAREHOUSE_TABLE_PACKING,
        });
        const today = moment().format("YYYYMMDD");
        const jsonStore = JSON.stringify({
          date: today,
          code: table.code,
          id: table.id,
        });
        localStorage.setItem("_pktb", jsonStore);
        document.getElementById("success_sound_player").play();
        this.hideConfirmDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    async scanBasket() {
      if (!this.code) {
        return false;
      }
      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const basket_code = localStorage.getItem("_pbct1");

      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/packing-scan-basket",
          {
            code: this.code,
          }
        );
        const { basket, command, orders, is_missing } = data;
        const presentItems = [];

        if (basket_code && basket_code != basket.code) {
          this.$vToastify.error(
            this.$t("messages.not_finish_basket_one", {
              basket_code: basket_code,
            })
          );
          document.getElementById(`ChuaHoanThanhSot1SP_player`).play();
          this.isLoading = false;
          this.code = null;
          return false;
        }
        if (command.process_type == 1) {
          localStorage.setItem("_pbct1", basket.code);
        }

        this.is_missing = is_missing;
        if (this.is_missing && !this.isNotifyMissing) {
          this.isNotifyMissing = true;
          this.$vToastify.error(this.$t("messages.missing_basket"));
          document.getElementById(`SotBaoThieu_player`).play();
        }

        const uOrders = [...orders].map((o) => {
          const oItems1 = o.items.split(";");
          const oItems2 = [...oItems1].map((oi) => {
            const [
              id_goods,
              sku,
              sub_barcode,
              quantity,
              uid_printed_on_item,
              no_label,
              uid_scan_require,
              is_present,
            ] = oi.split("Ω");
            return {
              id_goods,
              sku,
              sub_barcode,
              quantity,
              uid_printed_on_item,
              no_label,
              uid_scan_require,
              is_present,
            };
          });
          const oItems3 = [];
          oItems2.forEach((io) => {
            const checkIO3 = oItems3.findIndex(
              (io3) => io3.id_goods == io.id_goods
            );
            io.quantity = +io.quantity;
            if (checkIO3 < 0) {
              oItems3.push(io);
            } else {
              oItems3[checkIO3].quantity += io.quantity;
            }

            if (io.is_present == "1" && !presentItems.includes(io.id_goods)) {
              presentItems.push(io.id_goods);
            }
          });
          o.items = [...oItems3];
          return o;
        });

        /*const printedTracking = window.me && window.me.working && window.me.working.packing && window.me.working.packing_item || null
        if (printedTracking) {
          const order = uOrders.find(o => o.tracking_id == printedTracking)
          if (!order) {
            this.$vToastify.error(this.$t('messages.pack_error_stamp_pending_1',{tracking: printedTracking}))
            document.getElementById('error_sound_player').play()
            this.code = null
            this.isLoading = false
            return false
          }
        }*/

        this.basket = { ...basket };
        this.command = { ...command };
        this.orders = [...uOrders];
        this.presentItems = [...presentItems];
        console.log(this.presentItems);

        this.isLoading = false;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        if (errMsg == "Không tìm thấy sọt" && this.code == basket_code) {
          localStorage.removeItem("_pbct1");
        }
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },
    async success() {
      if (this.orders.length > 1) {
        this.code = this.basket.code;
        await this.scanBasket();
      } else {
        this.finishBasket();
      }
    },
    finishBasket() {
      localStorage.removeItem("_pbct1");
      this.code = null;
      this.basket = {};
      this.command = {};
      this.orders = [];
      this.is_missing = false;
      this.isNotifyMissing = false;
    },
  },
};
</script>

<style scoped></style>
